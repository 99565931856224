export const PropertyType = [
  { value: "casas", name: "Casas" },
  { value: "apartamentos", name: "Apartamentos" },
  { value: "terrenos", name: "Terrenos" },
  { value: "camposychacras", name: "Campos y Chacras" },
  {
    value: "galponesylocalescomerciales",
    name: "Galpones y Locales Comerciales",
  },
  { value: "remates", name: "Remates" },
]

export const ListType = [
    { value: "ventas", name: "Ventas" },
    { value: "alquileres", name: "Alquileres" },
]

export const Currency =[
  {value: "U$S", name: "U$S"}, 
  {value: "$", name: "$"}, 
]

export const MinPrice = [
  { value: '1000', name: '1.000' },
    { value: '50000', name: '50.000' },
    { value: '100000', name: '100.000' },
    { value: '150000', name: '150.000' },
    { value: '200000', name: '200.000' },
    { value: '250000', name: '250.000' },
    { value: '300000', name: '300.000' },
    { value: '400000', name: '400.000' },
    { value: '450000', name: '450.000' },
    { value: '500000', name: '500.000' },
    { value: '550000', name: '550.000' },
    { value: '600000', name: '600.000' },
    { value: '750000', name: '750.000' },
    { value: '800000', name: '800.000' },
    { value: '950000', name: '950.000' },
    { value: '1000000', name: '1.000.000' }
]

export const MaxPrice = [
  { value: '1000', name: '1.000' },
  { value: '50000', name: '50.000' },
  { value: '100000', name: '100.000' },
  { value: '150000', name: '150.000' },
  { value: '200000', name: '200.000' },
  { value: '250000', name: '250.000' },
  { value: '300000', name: '300.000' },
  { value: '400000', name: '400.000' },
  { value: '450000', name: '450.000' },
  { value: '500000', name: '500.000' },
  { value: '550000', name: '550.000' },
  { value: '600000', name: '600.000' },
  { value: '750000', name: '750.000' },
  { value: '800000', name: '800.000' },
  { value: '950000', name: '950.000' },
  { value: '1000000', name: '1.000.000' }
]

